import React from 'react'
import AboutSection from '../Common-component/AboutSection'
import Counter from '../Common-component/Counter'
import CoursesSection from '../Common-component/CoursesSection'

function Home() {
    return (

        /*Hero Section start*/
        <main className="main">
            <section id="hero" className="hero section dark-background">
                <img src="assets/img/hero-bg.jpg" alt="Hero Background" />
                <div className="container">
                    <h2>Learning Today,<br />Leading Tomorrow</h2>
                    <p>We are a team of talented designers making websites with Bootstrap</p>
                    <div className="d-flex mt-4">
                        <a href="courses.html" className="btn-get-started"> Get Started</a>
                    </div>
                </div>
            </section>
            {/* <!-- /Hero Section end--> */}



            {/* about section start */}
                <AboutSection />
            {/* about section end */}



            {/* -- Counts Section start-- */}
                <Counter />
            {/* -- Counts Section end-- */}



            {/* <!-- /Why Us Section --> */}
            <section id="why-us" className="section why-us">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4">
                            <div class="why-box">
                                <h3>Why Choose Our Products?</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
                                    Asperiores dolores sed et. Tenetur quia eos. Autem tempore quibusdam vel necessitatibus optio ad corporis.
                                </p>
                                <div className="text-center">
                                    <a href=".." className="more-btn"><span>Learn More</span> <i class="bi bi-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 d-flex align-items-stretch">
                            <div className="row gy-4">
                                <div className="col-xl-4">
                                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                        <i className="bi bi-clipboard-data"></i>
                                        <h4>Corporis voluptates officia eiusmod</h4>
                                        <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                                    </div>
                                </div>

                                <div className="col-xl-4">
                                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                        <i className="bi bi-gem"></i>
                                        <h4>Ullamco laboris ladore pan</h4>
                                        <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                                    </div>
                                </div>

                                <div className="col-xl-4">
                                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                        <i className="bi bi-inboxes"></i>
                                        <h4>Labore consequatur incidid dolore</h4>
                                        <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- /Why Us Section --> */}



            {/* <!-- Features Section --> */}
            <section id="features" className="features section">
                <div className="container">
                    <div className="row gy-4">

                        <div className="col-lg-3 col-md-4">
                            <div className="features-item">
                                <i className="bi bi-eye" style={{ color: "#ffbb2c" }}></i>
                                <h3><a href=".." className="stretched-link">Lorem Ipsum</a></h3>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4">
                            <div className="features-item">
                                <i className="bi bi-infinity" style={{ color: "#5578ff" }}></i>
                                <h3><a href=".." className="stretched-link">Dolor Sitema</a></h3>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-4">
                            <div className="features-item">
                                <i className="bi bi-mortarboard" style={{ color: "#e80368" }}></i>
                                <h3><a href=".." className="stretched-link">Sed perspiciatis</a></h3>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-4">
                            <div className="features-item">
                                <i className="bi bi-nut" style={{ color: "#e361ff" }}></i>
                                <h3><a href=".." className="stretched-link">Magni Dolores</a></h3>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-4">
                            <div className="features-item">
                                <i className="bi bi-shuffle" style={{ color: "#47aeff" }}></i>
                                <h3><a href=".." className="stretched-link">Nemo Enim</a></h3>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-4">
                            <div className="features-item">
                                <i className="bi bi-star" style={{ color: "#ffa76e" }}></i>
                                <h3><a href=".." className="stretched-link">Eiusmod Tempor</a></h3>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-4">
                            <div className="features-item">
                                <i className="bi bi-x-diamond" style={{ color: "#11dbcf" }}></i>
                                <h3><a href=".." className="stretched-link">Midela Teren</a></h3>
                            </div>
                        </div>



                        <div className="col-lg-3 col-md-4">
                            <div className="features-item">
                                <i className="bi bi-camera-video" style={{ color: '#4233ff' }}></i>
                                <h3><a href=".." className="stretched-link">Pira Neve</a></h3>
                            </div>
                        </div>



                        <div className="col-lg-3 col-md-4" >
                            <div className="features-item">
                                <i className="bi bi-command" style={{ color: "#b2904f" }}></i>
                                <h3><a href=".." className="stretched-link">Dirada Pack</a></h3>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-4" >
                            <div className="features-item">
                                <i className="bi bi-dribbble" style={{ color: "#b20969" }}></i>
                                <h3><a href=".." className="stretched-link">Moton Ideal</a></h3>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4" >
                            <div className="features-item">
                                <i className="bi bi-activity" style={{ color: "#ff5828" }}></i>
                                <h3><a href=".." className="stretched-link">Verdo Park</a></h3>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4" >
                            <div className="features-item">
                                <i className="bi bi-brightness-high" style={{ color: "#29cc61" }}></i>
                                <h3><a href=".." className="stretched-link">Flavor Nivelanda</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- /Features Section end --> */}



            {/* <!-- Courses Section start--> */}
            <section id="courses" className="courses section">
                <div className="container section-title">
                    <h2>Courses</h2>
                    <p>Popular Courses</p>
                </div>
                <CoursesSection/>
            </section>
            {/* <!-- /Courses Section end--> */}


            {/* <!-- Trainers Index Section start --> */}
            <section id="trainers-index" className="section trainers-index">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 d-flex">
                            <div className="member">
                                <img src="assets/img/trainers/trainer-1.jpg" className="img-fluid" alt="..." />
                                <div className="member-content">
                                    <h4>Walter White</h4>
                                    <span>Web Development</span>
                                    <p>
                                        Magni qui quod omnis unde et eos fuga et exercitationem. Odio veritatis perspiciatis quaerat qui aut aut aut
                                    </p>
                                    <div className="social">
                                        <a href=".."><i className="bi bi-twitter-x"></i></a>
                                        <a href=".."><i className="bi bi-facebook"></i></a>
                                        <a href="..."><i className="bi bi-instagram"></i></a>
                                        <a href=".."><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex">
                            <div className="member">
                                <img src="assets/img/trainers/trainer-2.jpg" className="img-fluid" alt="..." />
                                <div className="member-content">
                                    <h4>Sarah Jhinson</h4>
                                    <span>Marketing</span>
                                    <p>
                                        Repellat fugiat adipisci nemo illum nesciunt voluptas repellendus. In architecto rerum rerum temporibus
                                    </p>
                                    <div className="social">
                                        <a href=".."><i className="bi bi-twitter-x"></i></a>
                                        <a href=".."><i className="bi bi-facebook"></i></a>
                                        <a href=".."><i className="bi bi-instagram"></i></a>
                                        <a href=".."><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex">
                            <div className="member">
                                <img src="assets/img/trainers/trainer-3.jpg" className="img-fluid" alt="...." />
                                <div className="member-content">
                                    <h4>William Anderson</h4>
                                    <span>Content</span>
                                    <p>
                                        Voluptas necessitatibus occaecati quia. Earum totam consequuntur qui porro et laborum toro des clara
                                    </p>
                                    <div className="social">
                                        <a href=".."><i className="bi bi-twitter-x"></i></a>
                                        <a href=".."><i className="bi bi-facebook"></i></a>
                                        <a href=".."><i className="bi bi-instagram"></i></a>
                                        <a href=".."><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- /Trainers Index Section end--> */}

        </main>
    )
}

export default Home