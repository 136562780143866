import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Layout from './Pages/Layout';
import Home from './Pages/Home';
import Banner from './Common-component/Banner';
import About from './Pages/About';
import Courses from './Pages/Courses';
import Trainers from './Pages/Trainers'
import Pricing from './Pages/Pricing';
import Contact from './Pages/Contact';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about"element={
              <>
                <Banner />
                <About />
              </>
            }/>
          <Route
            path="/courses"
            element={
              <>
                <Banner />
                <Courses/>
              </>
            }/>
          <Route
            path="/trainers"
            element={
              <>
                <Banner />
                <Trainers/>
              </>
            }/>
          <Route
            path="/pricing"
            element={
              <>
                <Banner />
                <Pricing/>
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Banner />
                <Contact/>
              </>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
