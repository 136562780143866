import { Outlet } from "react-router-dom"
import Footer from "../Common-component/Footer"
import Header from "../Common-component/Header"

export default function Layout()
{
    return(<div>
        <Header />
         <Outlet />
         <Footer />
    </div>)
}