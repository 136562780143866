import React from 'react'
import AboutSection from '../Common-component/AboutSection'
import Counter from '../Common-component/Counter'


function About() {
    return (
    <>
        <main>
        <AboutSection imageSrc="assets/img/about-2.jpg"/>
        <Counter />

        <section id="testimonials" className="testimonials section">
            <div className="container section-title">
                <h2>Testimonials</h2>
                <p>What are they saying</p>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="swiper init-swiper">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                            <div className="testimonial-wrap">
                                                <div className="testimonial-item">
                                                    <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt=".." />
                                                    <h3>Saul Goodman</h3>
                                                    <h4>Ceo &amp; Founder</h4>
                                                    <div className="stars">
                                                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                    </div>
                                                    <p>
                                                        <i className="bi bi-quote quote-icon-left"></i>
                                                        <span>Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.</span>
                                                        <i className="bi bi-quote quote-icon-right"></i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="swiper init-swiper">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="testimonial-wrap">
                                                <div className="testimonial-item">
                                                    <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt=".." />
                                                    <h3>Saul Goodman</h3>
                                                    <h4>Ceo &amp; Founder</h4>
                                                    <div className="stars">
                                                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                    </div>
                                                    <p>
                                                        <i className="bi bi-quote quote-icon-left"></i>
                                                        <span>Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.</span>
                                                        <i className="bi bi-quote quote-icon-right"></i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default About