import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer id="footer" className="footer position-relative light-background">

      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6 footer-about">
            <a href="index.html" className="logo d-flex align-items-center">
              <span className="sitename">KUMARAWAT WEB</span>
            </a>
            <div className="footer-contact pt-3">
              <p>Annaporna road </p>
              <p>Indore</p>
              <p className="mt-3"><strong>Phone:</strong> <span>+91 9993640149</span></p>
              <p><strong>Email:</strong> <span>info@Kumrawatweb.site</span></p>
            </div>
            <div className="social-links d-flex mt-4">
              <a href=".."><i className="bi bi-twitter-x"></i></a>
              <a href=".."><i className="bi bi-facebook"></i></a>
              <a href=".."><i className="bi bi-instagram"></i></a>
              <a href=".."><i className="bi bi-linkedin"></i></a>
            </div>
          </div>

          <div className="col-lg-2 col-md-3 footer-links">
            <h4>Useful Links</h4>
            <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/courses">Courses</Link></li>
          <li><Link to="/trainers">Trainers</Link></li>
          <li><Link to="/pricing">Pricing</Link></li>
          <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>

          <div className="col-lg-2 col-md-3 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><Link to="#">Web Design</Link></li>
              <li><Link to="#">Web Development</Link></li>
              <li><Link to="#">Product Management</Link></li>
              <li><Link to="#">Marketing</Link></li>
              <li><Link to="#">Graphic Design</Link></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-12 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
            <form>
              <div className="newsletter-form">
                <input type="email" name="email" />
                <input type="submit" value="Subscribe" />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="container copyright text-center mt-4">
        <p>© <span>Copyright</span> <strong className="px-1 sitename">KumrawatWeb</strong> <span>All Rights Reserved</span></p>
        <div className="credits">
          Designed by <a href="https://kumrawatweb.site/">Kumrawat Web Services</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
