import React from 'react';
import { useLocation } from 'react-router-dom';
import './banner.css';

export default function Banner() {
    const location = useLocation();


    const getPageTitle = () => {
        const path = location.pathname;
        const pageName = path.split('/').filter(Boolean).pop(); 
        return pageName ? pageName.charAt(0).toUpperCase() + pageName.slice(1) : 'Home';
    };

    return (
        <>
            {/* Banner section start */}
            <div className="page-tit">
                <div className="head">
                    <div className="container">
                        <div className="row d-flex justify-content-center text-center">
                            <div className="col-lg-8">
                                <h1>{getPageTitle()}<br /></h1>
                                <p className="mb-0">
                                    Odio et unde deleniti. Deserunt numquam exercitationem. Officiis quo odio sint voluptas 
                                    consequatur ut a odio voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi ratione sint. 
                                    Sitquaerat ipsum dolorem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Banner section end */}
        </>
    );
}
