import React from 'react'

export default function Trainers() {
    return (
        <> 
        
            <section id="trainers" className="section trainers">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-4 col-md-6 member">
                            <div className="member-img">
                                <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="..." />
                                <div className="social">
                                    <a href=".."><i className="bi bi-twitter-x"></i></a>
                                    <a href=".."><i className="bi bi-facebook"></i></a>
                                    <a href=".."><i className="bi bi-instagram"></i></a>
                                    <a href=".."><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Walter White</h4>
                                <span>Business</span>
                                <p>Aliquam iure quaerat voluptatem praesentium possimus unde laudantium vel dolorum distinctio dire flow</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 member" >
                            <div className="member-img">
                                <img src="assets/img/team/team-2.jpg" className="img-fluid" alt=".." />
                                <div className="social">
                                    <a href=".."><i className="bi bi-twitter-x"></i></a>
                                    <a href=".."><i className="bi bi-facebook"></i></a>
                                    <a href=".."><i className="bi bi-instagram"></i></a>
                                    <a href=".."><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Sarah Jhonson</h4>
                                <span>Marketing</span>
                                <p>Labore ipsam sit consequatur exercitationem rerum laboriosam laudantium aut quod dolores exercitationem ut</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 member" >
                            <div className="member-img">
                                <img src="assets/img/team/team-3.jpg" className="img-fluid" alt=".." />
                                <div className="social">
                                    <a href=".."><i className="bi bi-twitter-x"></i></a>
                                    <a href=".."><i className="bi bi-facebook"></i></a>
                                    <a href=".."><i className="bi bi-instagram"></i></a>
                                    <a href=".."><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>William Anderson</h4>
                                <span>Maths</span>
                                <p>Illum minima ea autem doloremque ipsum quidem quas aspernatur modi ut praesentium vel tque sed facilis at qui</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 member">
                            <div className="member-img">
                                <img src="assets/img/team/team-4.jpg" className="img-fluid" alt=".." />
                                <div className="social">
                                    <a href=".."><i className="bi bi-twitter-x"></i></a>
                                    <a href=".."><i className="bi bi-facebook"></i></a>
                                    <a href=".."><i className="bi bi-instagram"></i></a>
                                    <a href=".."><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Amanda Jepson</h4>
                                <span>Foreign Languages</span>
                                <p>Magni voluptatem accusamus assumenda cum nisi aut qui dolorem voluptate sed et veniam quasi quam consectetur</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 member" >
                            <div className="member-img">
                                <img src="assets/img/team/team-5.jpg" className="img-fluid" alt=".." />
                                <div className="social">
                                    <a href=".."><i className="bi bi-twitter-x"></i></a>
                                    <a href=".."><i className="bi bi-facebook"></i></a>
                                    <a href=".."><i className="bi bi-instagram"></i></a>
                                    <a href=".."><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Brian Doe</h4>
                                <span>Web Development<br /></span>
                                <p>Qui consequuntur quos accusamus magnam quo est molestiae eius laboriosam sunt doloribus quia impedit laborum velit</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 member">
                            <div className="member-img">
                                <img src="assets/img/team/team-6.jpg" className="img-fluid" alt=".." />
                                <div className="social">
                                    <a href=".."><i className="bi bi-twitter-x"></i></a>
                                    <a href=".."><i className="bi bi-facebook"></i></a>
                                    <a href=".."><i className="bi bi-instagram"></i></a>
                                    <a href=".."><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Josepha Palas</h4>
                                <span>Business</span>
                                <p>Sint sint eveniet explicabo amet consequatur nesciunt error enim rerum earum et omnis fugit eligendi cupiditate vel</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
