import React from 'react';
import { Link } from "react-router-dom";
import './header.css';

function Header() {
  const handleMenuClick = () => {
    // Collapse the navbar menu
    const navbarCollapse = document.getElementById("navbarNav");
    if (navbarCollapse.classList.contains("show")) {
      navbarCollapse.classList.remove("show");
    }
  };

  return (
    <header className="header_wrapper sticky-top">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/">KUMRAWAT WEB</Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fa-solid fa-bars-staggered"></i>
          </button>
          
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link menu" onClick={handleMenuClick}>Home</Link>
              </li>

              <li className="nav-item">
                <Link to="/about" className="nav-link menu" onClick={handleMenuClick}>About</Link>
              </li>

              <li className="nav-item">
                <Link to="/courses" className="nav-link menu" onClick={handleMenuClick}>Courses</Link>
              </li>

              <li className="nav-item">
                <Link to="/trainers" className="nav-link menu" onClick={handleMenuClick}>Trainers</Link>
              </li>

              <li className="nav-item">
                <Link to="/pricing" className="nav-link menu" onClick={handleMenuClick}>Pricing</Link>
              </li>

              <li className="nav-item">
                <Link to="/contact" className="nav-link menu" onClick={handleMenuClick}>Contact</Link>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
