import React from 'react'

export default function Counter() {
    return (
        <section id="counts" className="section counts light-background">
            <div className="container">
                <div className="row gy-4">

                    <div className="col-lg-3 col-md-6">
                        <div className="stats-item text-center w-100 h-100">
                            <span className="purecounter">1232</span>
                            <p>Students</p>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-6">
                        <div className="stats-item text-center w-100 h-100">
                            <span className="purecounter">64</span>
                            <p>Courses</p>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-6">
                        <div className="stats-item text-center w-100 h-100">
                            <span className="purecounter">42</span>
                            <p>Events</p>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-6">
                        <div className="stats-item text-center w-100 h-100">
                            <span className="purecounter">24</span>
                            <p>Trainers</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
