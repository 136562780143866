import React from "react";

export default function Contact() {
    return (
        <>
            {/* <!-- /Contact Section Start--> */}
            <section id="contact" className="contact section">
                <div className="mb-5">
                    <iframe
                        style={{ border: "0", width: "100%", height: "300px" }}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d223873.7906549931!2d75.69903738855072!3d22.72388828988633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcad1b410ddb%3A0x96ec4da356240f4!2sIndore%2C%20Madhya%20Pradesh!5e1!3m2!1sen!2sin!4v1734078497770!5m2!1sen!2sin" allowFullScreen={true} referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>

                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4">
                            <div className="info-item d-flex">
                                <i className="bi bi-geo-alt flex-shrink-0"></i>
                                <div>
                                    <h3>Address</h3>
                                    <p>Annaporna road Vishal Nager Indore</p>
                                </div>
                            </div>

                            <div className="info-item d-flex">
                                <i className="bi bi-telephone flex-shrink-0"></i>
                                <div>
                                    <h3>Call Us</h3>
                                    <p>+91 9993640149</p>
                                </div>
                            </div>

                            <div className="info-item d-flex">
                                <i className="bi bi-envelope flex-shrink-0"></i>
                                <div>
                                    <h3>Email Us</h3>
                                    <p>info@kumrawatweb.site</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row gy-4">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Your Name"
                                            required
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            placeholder="Your Email"
                                            required
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            rows="6"
                                            placeholder="Message"
                                            required
                                        ></textarea>
                                    </div>

                                    <div className="col-md-12 text-end">
                                        <button type="submit" className="btn btn-primary">
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- /Contact Section End--> */}
        </>
    );
}
